import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const List = Loadable(lazy(() => import('./List')));

const studentQuestionsRoutes = [
  { path: '/student-questions', element: <List />, auth: authRoles.admin },
];

export default studentQuestionsRoutes;
